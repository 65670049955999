import { FrontendEnvironment } from '@weavix/domain/src/frontend-environment/frontend-environment';
import { getMicroblinkLicense } from './environment.microblink';
import { WeavixEnv } from './environment.model';

const frontendEnvironment = new FrontendEnvironment(window.location.hostname);
const domain = frontendEnvironment.domain;

export const environment: WeavixEnv = {
    production: frontendEnvironment.isProduction(),
    radioUrl: frontendEnvironment.getSiblingUrl('radio'),
    is360Api: frontendEnvironment.getSiblingUrl('api'),
    pubsubApi: frontendEnvironment.getSiblingUrl('mqtt').replace('https://', ''),
    pubsubPort: frontendEnvironment.getPubSubPort(),
    analyticsSource: 'is360-webapp',
    version: 'swalt.1', // replaced during gitlab build - see set-ng-environment-version.sh
    commit: 'de39abcc8f30cbfea91795d50c44394bed328898', // replaced during gitlab build - see set-ng-environment-version.sh
    bugsnagReleaseStage: frontendEnvironment.getBugsnagReleaseStage(),
    enableRouteTracing: domain === 'localhost',
    enableReports: domain === 'localhost' || domain === 'weavixdev.com' || domain === 'weavixdemo.com',
    bugsnagApiKey: '35c3d21496296d09d13a27ca69b265f0',
    microblinkLicense: getMicroblinkLicense(domain),
};
